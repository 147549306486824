import React, { useState, useEffect } from "react";

const TodoNotes = () => {
    const [notes, setNotes] = useState(["Fetching..."])

    let appurl = window.location.origin + "/app/notes"

    useEffect( () => {
        fetch(
            appurl,
            {
                method: "GET"
            }
        )
        .catch((reason) => {
            //yikes.
            setNotes(["Couldn't fetch notes!", "Oh no!", "Heck"]);
        })
        .then((response) => {
            if (response.ok)
                return response.json();
            else
                return (["Couldn't fetch notes!", "Oh no!", "Heck"]);
        })
        .then((data) => {
            setNotes(data.notes);
        });
    }, [appurl]);

    return <>
        <div id="sticky-note">
            <h1>What I'm up to these days!</h1>
            <ul>
                {notes.map((note) => {
                    return <li>{note}</li>
                })}
            </ul>
        </div>
    </>
}

export default TodoNotes;