import React from 'react'
import ReactDOM  from 'react-dom/client'

import Mp3 from "./components/mp3-player"
import Notes from "./components/todo-notes"

const root = ReactDOM.createRoot(document.getElementById('asides'));
root.render(
    <>
        <Notes />
        <Mp3 />  
    </>
)