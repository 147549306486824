import React, { useState, useEffect } from "react";

const secsToMinSec = (length) => {
    if (typeof length !== "number")
        return "00:00"
    if (length < 0)
        length *= -1;

    let minutes = String(Math.floor(length / 60));
    let seconds = String(Math.floor(length % 60));

    if (minutes.length === 1)
        minutes = "0" + minutes;
    if (seconds.length === 1)
        seconds = "0" + seconds;

    return minutes + ":" + seconds
}

const Mp3Player = () => {
    const [artist, setArtist] = useState("Fetching");
    const [album, setAlbum] = useState("Music");
    const [title, setTitle] = useState("Info...");
    const [length, setLength] = useState(0);
    const [time, setTime] = useState("00:00");

    let appurl = window.location.origin + "/app/music"

    useEffect(() => {
        fetch(
            appurl,
            {
                method: "GET"
            }
        )
        .catch((reason) => {
            //yikes.
            setArtist("Couldn't");
            setAlbum("Fetch");
            setTitle("Music");
        })
        .then((response) => {
            if (response.ok)
                return response.json();
            else
                return [{
                   "artist": "Couldn't" ,
                   "album": "Fetch",
                   "title": "Music",
                   "seconds": 0
                }];
        })
        .then((data) => {
            console.log(data);
            if (data.artist && data.album && data.title) {
                setArtist(data.artist);
                setAlbum(data.album);
                setTitle(data.title);

                if (typeof data.seconds === "number")
                    setLength(data.seconds);
            }
        });
    }, [appurl]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            let now = new Date();
            let hours = now.getHours();
            let minutes = now.getMinutes();
            let m = " AM";

            if (hours > 12) {
                m = " PM";
                hours -= 12;
            } else if (hours === 0) {
                hours = 12;
            }

            let hStr = String(hours);
            let mStr = String(minutes);

            if (hStr.length === 1)
                hStr = "0" + hStr;
            if (mStr.length === 1)
                mStr = "0" + mStr;

            setTime(hStr + ":" + mStr + m);
        }, 1000);
 

        return () => clearInterval(interval);
    });


    return <>
        <div id="mp3-player">
            <div id="screen">
                <header>
                    Music
                </header>
                <div id="music">
                    <div>
                        <i class="fa-solid fa-compact-disc"></i>
                    </div>
                    <div>
                        <p id="music-artist">{artist}</p>
                        <p id="music-album">{album}</p>
                        <p id="music-title">{title}</p>
                    </div>                            
                </div>
                <div id="seekbar">
                    <div id="seekbar-outer"><div id="seekbar-inner"></div></div>
                    <div id="time-data">
                        <p id="time-current">{secsToMinSec(length*0.27)}</p>
                        <p><i class="fa-solid fa-play"></i></p>
                        <p id="time-length">{secsToMinSec(length)}</p>
                    </div>                            
                </div>
                <footer>
                    <p>{time}</p>
                    <i class="fa-solid fa-battery-three-quarters"></i>
                </footer>
            </div>
            
            <svg></svg>
        </div>
    </>;
}

export default Mp3Player;